import FirewallService from "@/services/firewall.service";
import UserService from "@/services/user.service";

const service = new FirewallService(),
    userService = new UserService();

import config from "@/config";
import {bus} from "@/helpers/bus";

const server_base = config[config.stage].ws_server;

export default {
  name: "ACL",

  data() {
    return {
      actions: [
        {value: 'allow', text: this.$t('allow')},
        {value: 'block', text: this.$t('block')},
        {value: 'allow_all', text: this.$t('allow_all')},
        {value: 'block_all', text: this.$t('block_all')},
      ],
      companyUsers: [],
      allUsers: [],
      userSearchResults: [],
      searchFields: ['FirstName', 'LastName'],
      search: "",
      searchTotal: 0,
      rules: [],
      newRule: {
        show: false,
        action: 'allow',
        ip: '',
        description: '',
        users: []
      },
      editRule: {
        show: false,
        _id: null,
        action: 'allow',
        ip: '',
        description: '',
        users: []
      },
      deleteRule: {
        show: false,
        id: null
      },
      isLoading: false,
      searchTerm: '',
      perPage: 50,
      totalRows: 0,
      currentPage: 1,
    }
  },

  computed: {
    imSuperAdmin() {
      return this.$getUser().Company.AdministrativePerson === this.$getUser()._id;
    }
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
      }

      const res = await service.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.rules = res.data.rules;
        this.totalRows = res.data.count;
      }
    },

    showNewRule() {
      this.newRule = {
        show: true,
        action: 'allow',
        ip: '',
        description: '',
        users: []
      };
    },

    async createRule() {
      this.isLoading = true;

      const res = await service.create({
        Action: this.newRule.action,
        Description: this.newRule.description,
        IP: this.newRule.ip,
        Users: this.newRule.users
      });

      this.closeModals();

      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: this.$t('success_creating')
        });

        await this.list();

      } else {
        this.$notify({
          type: 'error',
          message: this.$t('error_creating')
        });
      }

      this.isLoading = false;
    },

    showUpdateRule(rule) {
      this.editRule = {
        show: true,
        _id: rule._id,
        action: rule.Action,
        ip: rule.IP,
        description: rule.Description,
        users: rule.Users
      };
    },

    async updateRule() {
      this.isLoading = true;

      const res = await service.update({
        id: this.editRule._id,
        Action: this.editRule.action,
        Description: this.editRule.description,
        IP: this.editRule.ip,
        Users: this.editRule.users
      });

      this.closeModals();

      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: this.$t('success_editing')
        });

      } else {
        this.$notify({
          type: 'error',
          message: this.$t('error_editing')
        });
      }

      this.isLoading = false;
    },

    newActionChange(action) {
      if (action === 'allow_all') {
        this.newRule.action = 'allow';
        this.newRule.ip = '0.0.0.0'
      } else if (action === 'block_all') {
        this.newRule.action = 'block';
        this.newRule.ip = '0.0.0.0'
      }
    },

    editActionChange(action) {
      if (action === 'allow_all') {
        this.editRule.action = 'allow';
        this.editRule.ip = '0.0.0.0'
      } else if (action === 'block_all') {
        this.editRule.action = 'block';
        this.editRule.ip = '0.0.0.0'
      }
    },

    showDeleteModal(id) {
      this.deleteRule.id = id;
      this.deleteRule.show = true;
    },

    async removeRule() {
      this.isLoading = true;

      const res = await service.remove(this.deleteRule.id);

      this.closeModals();

      if (res && !res.error) {
        this.$notify({
          type: 'success',
          message: this.$t('success_deleting')
        });

        await this.list();

      } else {
        this.$notify({
          type: 'error',
          message: this.$t('error_deleting')
        })
      }

      this.isLoading = false;
    },

    closeModals() {
      this.deleteRule.show = false;

      this.newRule.show = false;
      this.newRule.users = [];

      this.editRule.show = false;
      this.editRule.users = [];
    },

    async loadUsers() {
      const res = await userService.companyUsers();
      if (res && !res.error) {
        const users = res.data.users;
        this.allUsers = users;

        this.companyUsers = {
          isAdmin: [],
          isBDC: [],
          isInventory: [],
          isManager: [],
          isAgent: [],
          isAccountant: [],
          isReception: [],
          isUser: [],
          isSeller: [],
        };

        for (const user of users) {
          if (user.permissions.isAdmin)
            this.companyUsers.isAdmin.push(user);

          else if (user.permissions.isBDC)
            this.companyUsers.isBDC.push(user);

          else if (user.permissions.isInventory)
            this.companyUsers.isInventory.push(user);

          else if (user.permissions.isManager)
            this.companyUsers.isManager.push(user);

          else if (user.permissions.isAgent)
            this.companyUsers.isAgent.push(user);

          else if (user.permissions.isAccountant)
            this.companyUsers.isAccountant.push(user);

          else if (user.permissions.isReception)
            this.companyUsers.isReception.push(user);

          else if (user.permissions.isUser)
            this.companyUsers.isUser.push(user);

          else if (user.permissions.isSeller)
            this.companyUsers.isSeller.push(user);
        }
      }
    },

    agentAvatar(id) {
      return `${server_base}/api/files/avatar/${id}`;
    },

    isIn(userId) {
      return this.newRule.users.findIndex(u => u._id === userId) > -1;
    },

    isInEdit(userId) {
      return this.editRule.users.findIndex(u => u._id === userId) > -1;
    },

    addUser(user) {
      const index = this.newRule.users.findIndex(u => u._id === user._id);

      if (index > -1)
        this.newRule.users.splice(index, 1);
      else
        this.newRule.users.push(user);
    },

    addUserEdit(user) {
      const index = this.editRule.users.findIndex(u => u._id === user._id);

      if (index > -1)
        this.editRule.users.splice(index, 1);
      else
        this.editRule.users.push(user);
    },

    newAddAll() {
      if (this.imSuperAdmin) {
        if (this.newRule.users.length === this.allUsers.length) {
          this.newRule.users = [];
        } else
          this.newRule.users = this.allUsers;

      } else {
        const admins = this.allUsers.filter(u => u.permissions.isAdmin);
        const adminCount = admins.length;

        if (this.newRule.users.length >= this.allUsers.length - adminCount) {
          this.newRule.users = this.newRule.users.filter(u => admins.includes(u));

        } else {
          for (const user of this.allUsers) {
            if (!this.newRule.users.includes(user) && !user.permissions.isAdmin) {
              this.newRule.users.push(user);
            }
          }
        }
      }
    },

    editAddAll() {
      if (this.imSuperAdmin) {
        if (this.editRule.users.length === this.allUsers.length) {
          this.editRule.users = [];
        } else
          this.editRule.users = this.allUsers;

      } else {
        const admins = this.allUsers.filter(u => u.permissions.isAdmin);
        const adminCount = admins.length;

        if (this.editRule.users.length >= this.allUsers.length - adminCount) {
          this.editRule.users = this.editRule.users.filter(u => admins.includes(u));

        } else {
          for (const user of this.allUsers) {
            if (!this.editRule.users.includes(user) && !user.permissions.isAdmin) {
              this.editRule.users.push(user);
            }
          }
        }
      }
    },

    onSearchUser(results) {
      this.userSearchResults = {
        isAdmin: [],
        isBDC: [],
        isInventory: [],
        isManager: [],
        isAgent: [],
        isAccountant: [],
        isReception: [],
        isUser: [],
        isSeller: [],
      };

      for (const user of results) {

        if (user.permissions.isAdmin) {
          this.userSearchResults.isAdmin.push(user);
          this.searchTotal++;
        } else if (user.permissions.isBDC) {
          this.userSearchResults.isBDC.push(user);
          this.searchTotal++;
        } else if (user.permissions.isInventory) {
          this.userSearchResults.isInventory.push(user);
          this.searchTotal++;
        } else if (user.permissions.isManager) {
          this.userSearchResults.isManager.push(user);
          this.searchTotal++;
        } else if (user.permissions.isAgent) {
          this.userSearchResults.isAgent.push(user);
          this.searchTotal++;
        } else if (user.permissions.isAccountant) {
          this.userSearchResults.isAccountant.push(user);
          this.searchTotal++;
        } else if (user.permissions.isReception) {
          this.userSearchResults.isReception.push(user);
          this.searchTotal++;
        } else if (user.permissions.isUser) {
          this.userSearchResults.isUser.push(user);
          this.searchTotal++;
        } else if (user.permissions.isSeller) {
          this.userSearchResults.isSeller.push(user);
          this.searchTotal++;
        }
      }
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },
  },

  async mounted() {
    await this.list();
  },

  watch: {
    async searchTerm() {
      await this.list();
    },
  },
}