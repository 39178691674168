import IService from './iservice';
import store from '@/store/store';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class FirewallService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async list(limit, skip, filters = {}) {
    try {
      this.addLoading('list');
      const response = await this.http.get(`${server_base}/api/firewall/rules?limit=${limit}&skip=${skip}`, filters);
      this.delLoading('list');
      return response.data;
    } catch (e) {
      this.delLoading('list');
      return null;
    }
  }

  async create(record) {
    try {
      const response = await this.http.post(`${server_base}/api/firewall/rules`, record);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async remove(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/firewall/rules`, {id});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async update(record) {
    try {
      const response = await this.http.put(`${server_base}/api/firewall/rules`, record);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default FirewallService;